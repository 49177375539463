import * as React from 'react'
import { useEffect } from 'react'
import Layout from '../components/layout'
import { Link, navigate } from 'gatsby'

const PageSuccess = () => {
  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 3000)
  }, [])
  return (
    <Layout>
      <div className="container--padded-sm py-resp-32-56">
        <section className="section-success">
          <div className="container">
            <div className="section-success__message">
              <h3 className="title text-center text-2xl">Thank you for the enquiry!</h3>
              <p className=" py-resp-6-14 text-center">
                We will get back to you with fruitful insights for our first chat
                within 48 hours.

            </p>
              <div className="action text-center">
                <p className="text-sm">You will be redirected to our homepage shortly...</p>
                <Link className="btn btn--grey btn--wide" to="/">
                  Go Back to Homepage
            </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default PageSuccess
